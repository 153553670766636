// Override default variables before the import
$body-bg: #fff;
$tooltip-bg: black;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

button {
  font-weight: 500;
}

::-webkit-resizer {
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent transparent;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-button-as button {
  max-width: 100%;
}

.dropdown-no-toggle button::after {
  display: none !important;
}

.dropdown-no-toggle button {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 100px !important;
}

.btn-sm,
.btn-group-sm > .btn {
  border-radius: 1000px;
}

.dropdown-toggle::after {
  position: absolute;
  top: 14px;
  right: 10px;
}

.nav-item button {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.max-lines-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tooltip-inner {
  background-color: $tooltip-bg;
  color: white;
  text-align: left;
}

.tooltip.bs-tooltip-right .tooltip-arrow::before {
  border-right-color: $tooltip-bg !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
  border-left-color: $tooltip-bg !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $tooltip-bg !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $tooltip-bg !important;
}

select,
textarea,
input,
.form-control {
  font-size: 16px !important;
  border-radius: 12px;
}

.btn-outline-secondary,
.dropdown-no-toggle {
  border-color: lightgray !important;
}

.btn-outline-secondary:focus,
.dropdown-no-toggle:focus {
  background: none !important;
  color: inherit;
}

.btn-outline-secondary:hover,
.dropdown-no-toggle:hover {
  background: #f9f9f9 !important;
  color: inherit;
}

.pink-btn {
  background: "#CF7A76" !important;
  color: white !important;
}
